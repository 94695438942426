import 'index.scss';
import '@mts-ds/core/build/root-tokens-common.css';
import '@mts-ds/core/build/theme-tokens.css';
import '@mts-ds/core/build/root-tokens-typography-v3.css';
import '@mts-ds/core/build/all.css';
import '@mts-ds/core/build/fonts-v3.css';

import { THEME_NAMES } from 'common/constants/theme-names';
import { initialization } from 'common/utils/initialization';
import { ToastList } from 'Components/ToastList';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

const applicationInitialization = async () => {
  window.ENV = await initialization();

  const { store } = await import('./redux/store');
  const { App } = await import('./App');

  const rootElement = document.getElementById('root');

  const root = ReactDOM.createRoot(
    rootElement as HTMLElement,
  );

  const savedTheme = localStorage.getItem('theme');
  document.documentElement.dataset.mtsTheme = savedTheme || THEME_NAMES.light;
  rootElement!.dataset.theme = savedTheme || THEME_NAMES.light;

  root.render(
    <Provider store={store}>
      <ToastList />
      <App />
    </Provider>,
  );
};

void applicationInitialization();

import { Toast } from '@mts-ds/granat-react';
import cn from 'classnames';
import { TOAST_HEIGHT, TOAST_TOP_PADDING } from 'common/constants/toast-layout-values';
import { IToast } from 'common/types/toast';
import classes from 'Components/ToastList/toast-list.module.scss';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers/rootReducer';

function ToastList() {
  const toastList = useSelector<RootState, IToast[]>((state) => state.userReducer.toastList);

  const calcToastWidth = useCallback((toastId: string) => {
    const toast = document.getElementById(toastId);
    return toast ? toast.offsetWidth : 0;
  }, []);
  return (
    <>
      {toastList.map((item, index) => (
        <div
          className={cn({
            [classes.toast]: true,
            [classes.errorToast]: item && item.type === 'error',
            [classes.successToast]: item && item.type === 'done',
          })}
          style={{
            top: `${TOAST_TOP_PADDING + index * TOAST_HEIGHT}px`,
            left: item && item.isMounted ? `calc(100% - ${calcToastWidth(item.id) + 40}px` : '110%',
          }}
          key={item.id}
          id={item.id}
        >
          <Toast
            title={item.title}
            type={item.type}
            description={item.description}
          />
        </div>
      ))}
    </>
  );
}

export default memo(ToastList);
